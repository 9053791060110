
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CartModule from '../store/modules/CartModule';
import LoadingModule from '../store/modules/LoadingModule';
import { AddOrUpdateCartEditModel, WebCartItem } from '../models/Cart';
import { StockMessage, WebProduct } from '../models/Product';
import AddToCartStock from './AddToCartStock.vue';
import TrackingService from '../services/TrackingService';
import { Debounce } from 'vue-debounce-decorator';

const trackingService: TrackingService = new TrackingService();

@Component({
    name: 'AddToCartButton',
    components: {
        AddToCartStock
    }
})
export default class AddToCartButton extends Vue {
    @Prop({ type: Object, required: false, default: null }) private product: WebProduct;
    @Prop({ type: String, required: false, default: '' }) private sku: string;
    @Prop({ type: String, required: false, default: '' }) private variantid: string;
    @Prop({ type: String, required: false, default: '' }) private unit: string;
    @Prop({ type: Number, required: false, default: 1 }) private quantity: number;
    @Prop({ type: Number, required: false, default: 1 }) private colli: number;
    @Prop({ type: Object, required: false, default: null }) private stockMessage: StockMessage;
    @Prop({ type: Boolean, required: false, default: true }) private displayModal: boolean;
    @Prop({ type: Boolean, required: false, default: true }) private displayPreview: boolean;
    @Prop({ type: Boolean, required: false, default: false }) private forceIncrementation: boolean;
    @Prop({ type: String, required: false, default: 'default' }) private size: string;
    @Prop({ type: Array, required: false }) private addonProducts: string[];
    @Prop({ type: String, required: false, default: null }) private buttonText: string;
    @Prop({ type: String, required: false, default: null }) private colliText: string;

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get translationsReady(): boolean {
        return (this.buttonText != null && this.colliText != null) || this.$root.$data.translationsLoaded === true;
    }

    get fallbackButtonText(): any {
        return this.buttonText ?? this.$t('product.add-to-cart', ['Buy now']);
    }

    get fallbackColliText(): any {
        return this.colliText ?? this.$t('product.colli-notice', [this.colli, this.unit, 'Sold in packages of {0} {1}']);
    }

    get sizeClasses(): any {
        const retval: any = {
            input: '',
            button: '',
            iconRatio: '.8',
        };
        if (this.size !== 'medium') {
            retval.input = `uk-form-${this.size}`;
            retval.button = `uk-button-${this.size}`;
        }
        if (this.size === 'small') {
            retval.iconRatio = '.6';
        }
        if (this.size === 'large') {
            retval.iconRatio = '1.1';
        }

        return retval;
    }

    get productId(): string {
        if (this.product) {
            this.colli = this.product.colli;
            this.unit = this.product.unit;
            this.stockMessage = this.product.stockmessage;
            return this.product.id;
        } else if (this.sku) {
            return this.sku;
        }
        return 'SKU_MISSING';
    }

    get itemFromLookup(): WebCartItem {

        const variantid = this.variantid;
        
        return CartModule.CART_LOOKUP[`${this.productId}###${variantid ?? ''}`];
    }

    get enableAddedToCartModal(): boolean {
        return (window as any).CbxEnableAddedToCartModal;
    }

    get payload(): AddOrUpdateCartEditModel {
        return {
            productid: this.productId,
            quantity: this.quantity,
            variantid: this.variantid,
            addonproductids: this.addonProducts ?? [],
        };
    }

    private isNumber(event: any) {
        return event.charCode >= 48 && event.charCode <= 57;
    }

    private async add() {
        if (this.isLoading && (!this.forceIncrementation || this.itemFromLookup))
            return false;

        if (this.forceIncrementation && !this.itemFromLookup)
            this.payload.quantity++;

        // respect colli 
        this.payload.quantity = Math.ceil(this.payload.quantity / this.colli) * this.colli;

        await CartModule.ADD_TO_CART(this.payload);

        // track add to cart
        trackingService.trackAddToCart(this.itemFromLookup);

        if (this.enableAddedToCartModal && this.displayModal) {
            
            const variantid = this.variantid;

            CartModule.SET_ADD_TO_CART_MODAL({
                show: true,
                productId: this.sku || this.product.id,
                variantId: variantid
            });
        } else if (this.displayPreview) {
            CartModule.SET_IS_OPEN(true);
        }
    }

    private async increment() {
        await this.updateQuantity(this.itemFromLookup.quantity + this.colli);

        // track add to cart
        trackingService.trackAddToCart(this.itemFromLookup);        
    }

    private async decrement() {
        await this.updateQuantity(this.itemFromLookup.quantity - this.colli);
    }

    private async updateQuantity(quantity: number) {
        if (typeof quantity !== 'number') {
            return;
        }

        // respect colli 
        quantity = Math.ceil(quantity / this.colli) * this.colli;

        LoadingModule.SET_LOADING(true);
        this.payload.quantity = quantity;
        await CartModule.ADD_TO_CART(this.payload);
        if (this.payload.quantity === 0) {
            this.payload.quantity = this.quantity;
        }
        LoadingModule.SET_LOADING(false);
    }

    @Watch("quantity")
    @Debounce(500)
    private onQuantityChange() {
        if (this.itemFromLookup != null)
            this.updateQuantity(this.quantity);
    }
}
