export interface LinkModel {
    link: string;
    title: string;
}

export interface NavigationItemResponse {
    parent?: NavigationItem;
    children: NavigationItem[];
}

export interface NavigationItem extends LinkModel {
    haschildren: boolean;
    openinnewwindow: boolean;
    id: string;
    parent: string;
    type: NavigationItemType;
    customclass?: string;
    customcss?: string;
}

export enum NavigationItemType {
    Content = 0,
    Category = 1,
}
